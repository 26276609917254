import { CommonModule } from '@angular/common';
import { Input, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiErrorComponent } from './api-error/api-error.component';
import { BusyComponent } from './busy/busy.component';
import { LoadingComponent } from './loading/loading.component';
import { TimeAgoPipe } from './pipes/date/time-ago';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ToastModule } from './toast/toast.module';
import { InvalidFeedbackComponent } from './invalid-feedback/invalid-feedback.component';
import { NoResultComponent } from './no-result/no-result.component';
import { InputLengthComponent } from './input-length/input-length.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { ProgressModalComponent } from './progress-modal/progress-modal.component';
import { PaginatorComponent } from './pagination/components/paginator/paginator.component';
import { SortHeaderDirective } from './directives/sort-header.directive';
import { FlatpickrModule } from 'angularx-flatpickr';
import { LineBreakPipe } from './pipes/formatting/line-break.pipe';

export { ApiErrorModel } from './api-error/api-error.model';
export { TimePickerComponent } from './time-picker/time-picker.component';
export * from './enums';
export * from './helpers/http.helpers';
export * from './pagination';
export * from './tinymce';
export * from './validators/validators';

export {
  ToastContainerComponent,
  ToastService,
  ToastModule
} from './toast/toast.module';

@NgModule({
  declarations: [
    ApiErrorComponent,
    BusyComponent,
    IndicatorComponent,
    InputLengthComponent,
    InvalidFeedbackComponent,
    LoadingComponent,
    NoResultComponent,
    PaginatorComponent,
    ProgressModalComponent,
    TimePickerComponent,

    SortHeaderDirective,

    LineBreakPipe,
    TimeAgoPipe
  ],
  imports: [
    CommonModule,
    FlatpickrModule.forRoot(),
    FormsModule,
    ToastModule
  ],
  exports: [
    ToastModule,

    ApiErrorComponent,
    BusyComponent,
    IndicatorComponent,
    InputLengthComponent,
    InvalidFeedbackComponent,
    LoadingComponent,
    NoResultComponent,
    PaginatorComponent,
    ProgressModalComponent,
    TimePickerComponent,

    SortHeaderDirective,

    LineBreakPipe,
    TimeAgoPipe
  ]
})
export class SharedModule { }
