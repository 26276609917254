import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BranchesService } from '../branches/branches.service';
import { INotification } from '../notifications/models/notification.model';
import { DateTime } from 'luxon';
import * as signalR from '@microsoft/signalr';
import { IBranch } from '../branches/models/branch.model';

export interface ISignalRSmsReceived {
  branchRef: number;
  customerRef: number;
  incomingSmsId: number;
  smsRef: number;
}

export interface IObjectAction {
  id: number | string;
  action: string;
  data?: any[];
}

@Injectable({ providedIn: 'root' })
export class SignalRService {

  constructor(
    @Inject('MANAGE_URL') manageUrl: string,
    private _authService: AuthService,
    private _branchesService: BranchesService,
    private _logger: NGXLogger
  ) {
    this._configureManageConnection(manageUrl);
    this._bindBranchesService();
  }

  private _authToken: string;
  private _branch?: IBranch;
  private _manageConnection: signalR.HubConnection;

  private readonly _connected$ = new BehaviorSubject<boolean>(false);

  public readonly appointmentBookRefreshDate$ = new Subject<DateTime>();
  public readonly booking$ = new Subject<IObjectAction>();
  public readonly connected$ = this._connected$.asObservable();
  public readonly notificationAllRead$ = new Subject<void>();
  public readonly notificationRead$ = new Subject<number>();
  public readonly notificationPosted$ = new Subject<INotification>();
  public readonly sales$ = new Subject<IObjectAction>();
  public readonly salesRegister$ = new Subject<IObjectAction>();
  public readonly settingsUpdated$ = new Subject<string>();
  public readonly smsConversationRead$ = new Subject<string>();
  public readonly smsReceived$ = new Subject<ISignalRSmsReceived>();
  public readonly smsSent$ = new Subject<number>();

  private _bindBranchesService() {
    this._branchesService.branch$.subscribe(async (branch) => {
      if (!!branch) {
        await this.connectAsync(branch);
      }
      else {
        await this.disconnectAsync();
      }
    });
  }

  private _configureManageConnection(manageUrl: string) {
    this._manageConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .withUrl(`${manageUrl}hubs/business`, {
        accessTokenFactory: () => this._authToken
      })
      .build();

    this._manageConnection.onclose(async () => await this._manageClosedAsync());

    this._manageConnection.on('AppointmentBookRefreshDate', (isoDate: string) => {
      const date = DateTime.fromISO(isoDate);
      this._logger.debug(`SignalR | AppointmentBookRefreshDate | ${isoDate}`);
      this.appointmentBookRefreshDate$.next(date);
    });

    this._manageConnection.on('NotificationRead', (notificationRef: number) => {
      this._logger.debug(`SignalR | NotificationRead | ${notificationRef}`);
      this.notificationRead$.next(notificationRef);
    });

    this._manageConnection.on('NotificationAllRead', () => {
      this._logger.debug(`SignalR | NotificationAllRead`);
      this.notificationAllRead$.next();
    });

    this._manageConnection.on(
      'NotificationPosted', (
        notificationRef: number,
        message: string,
        priority: number,
        objectType?: string,
        objectRef?: number
      ) =>
    {
      this._logger.debug(`SignalR | NotificationPosted | ${notificationRef}`);
      this.notificationPosted$.next({
        notificationRef,
        createdAt: new Date(),
        message,
        priority,
        objectType,
        objectRef
      });
    });

    this._manageConnection.on('booking', (action: string, headBookingId: number, data: any) => this.booking$.next({ id: headBookingId, action, data }));
    this._manageConnection.on('sale', (action: string, saleId: number, data: any) => this.sales$.next({ id: saleId, action, data }));
    this._manageConnection.on('salesRegister', (action: string, registerId: number) => this.salesRegister$.next({ id: registerId, action }));
    this._manageConnection.on('settings', (action: string, section: string) => this._handleSettings(action, section));
    this._manageConnection.on('test', (action: string, section: string) => console.error('test', action, section));

    this._manageConnection.on('SmsConversationRead', (mobileNumber: string) => {
      this._logger.debug('[SignalR].[SmsConversationRead]', mobileNumber);
      this.smsConversationRead$.next(mobileNumber);
    });

    this._manageConnection.on('SmsReceived', (incomingSmsId: number, smsRef: number, branchRef: number, customerRef: number) => {
      this._logger.debug('[SignalR].[SmsReceived]', smsRef);
      this.smsReceived$.next({
        branchRef,
        customerRef,
        incomingSmsId,
        smsRef
      });
    });

    this._manageConnection.on('SmsSent', (smsRef: number) => {
      this._logger.debug('[SignalR].[SmsSent]', smsRef);
      this.smsSent$.next(smsRef);
    });

    this._manageConnection.on('Test', () => {
      this._logger.debug('[SignalR].[Test]');
    });
  }

  private _handleSettings(action: string, section: string) {
    this._logger.debug('[SignalR].[settings]', action, section);

    switch (action) {
      case 'updated':
        this.settingsUpdated$.next(section);
        break;

      default:
        throw new Error(`Unknown settings section: ${section}`);
    }
  }

  private async _manageClosedAsync(): Promise<void> {
    this._logger.debug('[SignalR].[manageClosedAsync]');
    if (!!this._branch) {
      this._logger.debug('[SignalR].[manageClosedAsync] reconnecting...');
      await this.connectAsync(this._branch);
    }
  }

  public async connectAsync(branch: IBranch): Promise<void> {
    this._logger.info('[SignalR].[connectAsync]');

    if (!!this._branch) {
      if (this._branch.subscriptionId === branch.subscriptionId) { return; }
      if (this._branch.id === branch.id) { return; }

      await this.disconnectAsync();
    }

    this._branch = branch;

    if (!!branch) {
      this._authToken = this._authService.getAuthToken();

      await this._manageConnection.start().then(_ => {
        this._connected$.next(true);
      }).catch(error => {
        this._logger.error('[SignalR].[connectAsync]', error);
        this._connected$.next(false);
      });
    }
  }

  public async disconnectAsync(): Promise<void> {
    this._logger.debug('[SignalR].[disconnectAsync]');
    this._branch = undefined;

    if (this._manageConnection.state === signalR.HubConnectionState.Connected) {
      await this._manageConnection.stop();
      this._connected$.next(false);
    }
  }

  public postAppointmentBookRefreshDate(date: DateTime) {
    this._manageConnection.send('PostAppointmentBookRefreshDate', date.toISO());
  }

  public markNotificationAsRead(notificationRef: number) {
    this._manageConnection.send('PostNotificationRead', notificationRef);
  }

  public markNotificationAllRead() {
    this._manageConnection.send('PostNotificationAllRead');
  }

  public markSmsConversationAsRead(mobileNumber: string) {
    if (!this._manageConnection.connectionId) { return; }
    this._manageConnection.send('PostSmsConversationRead', mobileNumber);
  }

  public postBookingPencilConfirmed(headBookingRef: number) {
    this._manageConnection.send('PostBookingPencilConfirmed', headBookingRef);
  }

}
