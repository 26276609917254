import * as rg4js from 'raygun4js';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { concatMap, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { BRANCH_STORAGE_KEY } from './branches.constants';
import { IBranch } from './models/branch.model';

@Injectable({ providedIn: 'root' })
export class BranchesService {

  private readonly _branch$ = new BehaviorSubject<IBranch | null>(null);
  private readonly _branches$ = new BehaviorSubject([]);

  public currentBranchRef?: number;

  public readonly branch$ = this._branch$.asObservable();
  public readonly branches$ = this._branches$.asObservable();

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private _authService: AuthService,
    private _http: HttpClient,
    private _logger: NGXLogger
  ) {
    this._bindAuthService();
    this._bindBranch();
  }

  private _bindAuthService() {
    this._authService.user$.subscribe(user => {
      if (!user) {
        this._logger.debug('[BranchesService].[bindAuthService] no user');
        this._branch$.next(null);
      }
    });
  }

  private _bindBranch(): void {
    this._branch$
      .pipe(
        tap(branch => this._logger.trace('[BranchesService].[bindBranch] branch changed', branch))
      )
      .subscribe(branch => {
        this.currentBranchRef = branch?.id;

        if (!!branch) {
          rg4js('withCustomData', { branchRef: branch.id, branchName: branch.name, subscriptionRef: branch.subscriptionId });
        }
        else
        {
          rg4js('withCustomData', {});
        }
      });
  }

  private _getBranchFromStorage(): Observable<IBranch | null> {
    this._logger.trace('[BranchesService].[getBranchFromStorage]');

    const storedBranch = window.localStorage.getItem(BRANCH_STORAGE_KEY);
    if (!storedBranch) {
      this._logger.trace('[BranchesService].[getBranchFromStorage] no stored branch');
      return of(null);
    }

    this._logger.trace('[BranchesService].[getBranchFromStorage] stored branch', storedBranch);
    return of(JSON.parse(storedBranch));
  }

  private _addBranchToStorage(branch: IBranch): void {
    window.localStorage.setItem(BRANCH_STORAGE_KEY, JSON.stringify(branch));
  }

  getCurrentBranch(): Observable<IBranch | null> {
    this._logger.trace('[BranchesService].[getCurrentBranch]');

    return concat(
      this._branch$.pipe(
        take(1),
        filter(branch => !!branch),
        tap(() => { this._logger.trace('[BranchesService].[getCurrentBranch] local branch'); })
      ),
      this._getBranchFromStorage().pipe(
        filter(branch => !!branch),
        concatMap(branch => this.setBranch(branch)),
        tap(() => { this._logger.trace('[BranchesService].[getCurrentBranch] storage branch'); })
      ),
      this.getBranches().pipe(
        take(1),
        filter(branches => branches.length === 1),
        map(branches => branches[0]),
        filter(branch => !!branch),
        tap(branch => this._logger.trace('[BranchesService].[getCurrentBranch] first branch from list', branch)),
        concatMap(branch => this.setBranch(branch)),
        tap(() => { this._logger.trace('[BranchesService].[getCurrentBranch] only branch'); })
      ),
      this._branch$.asObservable()
    );
  }

  getBranches(): Observable<IBranch[]> {
    this._logger.trace('[BranchesService].[getBranches]');

    return this._http.get<IBranch[]>(`${this.baseUrl}api/subscriptions/branches`)
      .pipe(tap(x => this._branches$.next(x)));
  }

  getSubscriptionBranches(): Observable<IBranch[]> {
    this._logger.trace('[BranchesService].[getSubscriptionBranches]');
    return this._http.get<IBranch[]>(`${this.baseUrl}api/branches`);
  }

  setBranch(branch: IBranch | null): Observable<IBranch | null> {
    this._logger.trace('[BranchesService].[setBranch]');

    if (branch?.id === this._branch$.value?.id) {
      this._logger.trace('[BranchesService].[setBranch] no change');
      return of(null);
    }

    return this._authService.getBranchToken(branch)
      .pipe(
        tap(_ => {
          this._addBranchToStorage(branch);
          this._branch$.next(branch);
        }, error => {
          this._logger.error('[BranchesService].[setBranch]', error);
        }),
        map(_ => branch)
      );
  }

  update(branchId: number, branch: IBranch): Observable<IBranch> {
    return this._http.put<IBranch>(`${this.baseUrl}api/branches/${branchId}`, branch);
  }

}
